
import { Component, Vue } from 'vue-property-decorator';
import PromotionsPopup from '../components/promotions-popup.vue';

@Component({
    components: {
        PromotionsPopup,
    },
})
export default class PromotonsPopupPage extends Vue {}
